import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import ResizeHandler from './utils/resize-active'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()
})

document.addEventListener("DOMContentLoaded", function() {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add("animated");
        observer.unobserve(entry.target);
      }
    });
  });

  const fadeImages = document.querySelectorAll(".fade-in");
  fadeImages.forEach(image => {
    observer.observe(image);
  });
});
